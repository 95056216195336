import React, { useEffect, useState,useContext } from 'react';
import { download } from '../Service';
import { getTCO } from './Calculator';
import InputForm from './InputForm';
import { AuthContext } from "../AuthContext";
import { Navigate } from "react-router-dom"; 


const Controller = (props) => {
    const { token, loading } = useContext(AuthContext);

    const [templateData, setTemplateData] = useState(undefined);
    const [inputData, setInputData] = useState(undefined);
    const [tco, setTco] = useState(undefined);

    useEffect(() => {
        loadData();
    }, []);

    const loadData = () => {
        let template_record = {
            customer_name: '',
            site_count: 144,
            chance_observerd_call: 7,
            chance_ticket: 5,
            avg_call_length_minutes: 75,
            houlry_wage_usd_observer: 130,
            delay_likelihood: 5,
            person_likelihood: 0.1,
            avg_hourly_cost_usd_downtime: 1000,
            delay_virtual_hours: 6,
            delay_inperson_hours: 48,
            expense_usd_travel_labor: 1400,
            number_of_jump_boxes: 3600,
            yearly_lease_cost_usd_jump_box: 300,
            installation_cost_usd_jump_box: 200,
            yearly_maintenance_likelihood: 20,
            maintenance_cost_usd: 200,
            yoy_increase: 5,
            third_party_improvement: 95,
            third_party_annual_benefits: [100, 100, 100, 100, 100],
            virtual_downtime_improvement: 95,
            virtual_downtime_annual_benefits: [80, 100, 100, 100, 100],
            inperson_downtime_improvement: 95,
            inperson_downtime_annual_benefits: [100, 100, 100, 100, 100],
            jumpbox_lease_annual_benefit: [100, 100, 100, 100, 100],
            jumpbox_mtce_annual_benefit: [80, 100, 100, 100, 100]
        };
        setTemplateData(template_record);
        setInputData(template_record);
        setTco(getTCO(template_record));
    }
    const handleChange = (prop, value) => {
        console.log(prop, value);
        setInputData({ ...inputData, [prop]: value });
        setTco(getTCO(inputData));
        console.log(tco);
    };
    if (loading) {
        return null;
    }

    if (!token) {
        return <Navigate to="/login" replace />;
    }
    return <div>
        <nav className="navbar navbar-expand-lg header">
            <div className="container-fluid">
                <div className='navbar-brand'>
                    <img src="https://cyolo.io/build/assets/logo-light-B4XFouAI.svg" alt='Cyolo' />
                </div>
            </div>
        </nav>

        <div className="container" style={{ marginTop: "30px" }}>
            <div className='row py-3'>
                <div className='col-12'>
                    <InputForm inputData={inputData} handleChange={handleChange} />
                    <div className='d-flex justify-content-end'>
                        <button className="btn btn-primary" onClick={() => {
                            download("/download-file/", "cyolo.pdf", {...inputData, ...tco} , (response) => {
                                console.log(response);
                            });
                        }}>Generate</button>
                    </div>
                </div>
            </div>

            {inputData && <div className='row py-3'>
                <div className='col-12'>
                    {/* <div className='row'>
                        <div className='col-6'>
                            <div style={{ height: "500px" }}>
                                <ROIPie data={tco.total_reduction} />
                            </div>

                        </div>
                        <div className='col-6'>
                            <div style={{ height: "500px" }}>
                                <ROIPie data={tco.total_reduction} />
                            </div>
                        </div>
                    </div> */}
                    {/* <div className='row'>
                    <div className='col-4'>
                        <div style={{ height: "200px" }}>
                            <h5 style={{ marginLeft: "30px" }}>Reduction in observing 3rd Party</h5>
                            <ROIBeforeAfter data={tco.reduction_third_party} />
                        </div>
                    </div>
                    <div className='col-4'>
                        <div style={{ height: "200px" }}>
                            <h5 style={{ marginLeft: "30px" }}>Reduction in downtime for virtual tickets</h5>
                            <ROIBeforeAfter data={tco.reduction_virtual} />
                        </div>
                    </div>
                    <div className='col-4'>
                        <div style={{ height: "200px" }}>
                            <h5 style={{ marginLeft: "30px" }}>Reduction in downtime for in person tikets</h5>
                            <ROIBeforeAfter data={tco.reduction_inperson} />
                        </div>
                    </div>
                </div> */}
                </div>
            </div>
            }
        </div>
    </div>
}

export default Controller;
