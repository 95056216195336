import { saveAs } from "file-saver";

const download = async (url, file_name, payload) => {
    try {
        const env = '/api'
        // startLoading();
        const response = await fetch(`${env}` + url + file_name, {
            method: 'POST',
            body: JSON.stringify(payload),
            credentials: "include",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + localStorage.getItem('token')
            },
        });
        const blob = await response.blob();
        saveAs(blob, file_name);
        // onDone(file_name)
    } catch (error) {
        if (error.error) {
            error.error.forEach((err) => {
                console.log(err.message);
            });
            return;
        }
        const resMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        console.log("ERROR: ", resMessage);
    }
};

export { download };

