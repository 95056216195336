export const round = (value, precision) =>{
    let multiplier = Math.pow(10, precision || 0);
    return Math.round(value * multiplier) / multiplier;
  }

export const getTCO = (input) => {
        const third_party_observed_calls_per_day=input.site_count * input.chance_observerd_call/100;
        const third_party_average_length_of_calls=input.avg_call_length_minutes;
        const third_party_hourly_wage_of_observer=input.houlry_wage_usd_observer;
        const third_party_hours_spent_before=third_party_observed_calls_per_day * third_party_average_length_of_calls * 365/60;
        const third_party_cost_of_labor_before=third_party_hours_spent_before * third_party_hourly_wage_of_observer;
        const third_party_improvement=input.third_party_improvement;
        const third_party_hours_spent_after = third_party_hours_spent_before * (1 - third_party_improvement/100);
        const third_party_cost_of_labor_after=third_party_hours_spent_after * third_party_hourly_wage_of_observer;
        const third_party_cost_of_labor_improvement=third_party_cost_of_labor_before-third_party_cost_of_labor_after;
        const third_party_cost_of_labor_fte=input.third_party_annual_benefits.map(x=>((third_party_hours_spent_before-third_party_hours_spent_after)/2000) * x/100);
        const third_party_annual_benefit=input.third_party_annual_benefits.map(x=>(third_party_cost_of_labor_improvement/100) * x);

        const virtual_downtime_number_of_tickets_per_day=input.site_count * input.chance_ticket/100;
        const virtual_downtime_delay_likelihood=input.delay_likelihood;
        const virtual_downtime_avg_downtime_cost=input.avg_hourly_cost_usd_downtime;
        const virtual_downtime_length_of_delay=input.delay_virtual_hours;
        
        const virtual_downtime_number_of_delayed_fixes_before=virtual_downtime_number_of_tickets_per_day * virtual_downtime_delay_likelihood/100;
        const virtual_downtime_cost_before=virtual_downtime_number_of_delayed_fixes_before * virtual_downtime_avg_downtime_cost * virtual_downtime_length_of_delay;
        const virtual_downtime_improvement=input.virtual_downtime_improvement;
        const virtual_downtime_number_of_delayed_fixes_after=virtual_downtime_number_of_delayed_fixes_before * (1 - virtual_downtime_improvement/100);
        const virtual_downtime_cost_after=virtual_downtime_number_of_delayed_fixes_after * virtual_downtime_avg_downtime_cost * virtual_downtime_length_of_delay;
        const virtual_downtime_cost_improvement=virtual_downtime_cost_before-virtual_downtime_cost_after;
        const virtual_downtime_annual_benefit=input.virtual_downtime_annual_benefits.map(x=>(virtual_downtime_cost_improvement/100) * x * 365);

        const inperson_downtime_number_of_tickets_per_day=input.site_count * input.chance_ticket/100;
        const inperson_downtime_likelihood=input.person_likelihood;
        const inperson_avg_downtime_cost=input.avg_hourly_cost_usd_downtime;
        const inperson_downtime_length_of_delay=input.delay_inperson_hours;
        const inperson_downtime_travel_expenses=input.expense_usd_travel_labor;

        const inperson_downtime_number_of_delayed_fixes_before=inperson_downtime_number_of_tickets_per_day * inperson_downtime_likelihood/100;
        const inperson_downtime_cost_before=(inperson_downtime_number_of_delayed_fixes_before * inperson_avg_downtime_cost * inperson_downtime_length_of_delay) + (inperson_downtime_number_of_delayed_fixes_before * inperson_downtime_travel_expenses);
        const inperson_downtime_improvement=input.inperson_downtime_improvement;
        const inperson_downtime_number_of_delayed_fixes_after=inperson_downtime_number_of_delayed_fixes_before * (1 - inperson_downtime_improvement/100);
        const inperson_downtime_cost_after=inperson_downtime_number_of_delayed_fixes_after * inperson_avg_downtime_cost * inperson_downtime_length_of_delay;
        const inperson_downtime_cost_improvement=inperson_downtime_cost_before-inperson_downtime_cost_after;
        const inperson_downtime_annual_benefit=input.inperson_downtime_annual_benefits.map(x=>(inperson_downtime_cost_improvement/100) * x * 365);

        const jumpbox_lease_number_of_jumpboxes=input.number_of_jump_boxes;
        const jumpbox_lease_cost_per_year=input.yearly_lease_cost_usd_jump_box;
        const jumpbox_lease_installation_cost=input.installation_cost_usd_jump_box;
        const jumpbox_lease_hawdware_lease_cost=jumpbox_lease_number_of_jumpboxes*jumpbox_lease_cost_per_year;
        const jumpbox_lease_hardware_installation_cost=jumpbox_lease_number_of_jumpboxes*jumpbox_lease_installation_cost;
        const jumpbox_lease_annual_benefit=input.jumpbox_lease_annual_benefit.map((x,i)=>((jumpbox_lease_hawdware_lease_cost + (i>0?0:jumpbox_lease_hardware_installation_cost))/100) * x);

        const jumpbox_mtce_number_of_jumpboxes=input.number_of_jump_boxes;
        const jumpbox_mtce_likelihood=input.yearly_maintenance_likelihood;
        const jumpbox_mtce_cost=input.maintenance_cost_usd;

        const jumpbox_mtce_annual_cost=jumpbox_mtce_number_of_jumpboxes * jumpbox_mtce_likelihood/100 * jumpbox_mtce_cost;
        const jumpbox_mtce_annual_benefit=input.jumpbox_mtce_annual_benefit.map(x=>(jumpbox_mtce_annual_cost/100) * x);

        /*

            number_of_jump_boxes: 3600,
            yearly_lease_cost_usd_jump_box: 300,
            installation_cost_usd_jump_box: 200,
            yearly_maintenance_likelihood: 20,
            maintenance_cost_usd: 200,
            yoy_increase: 5
            */


        return {
            third_party_observed_calls_per_day: round(third_party_observed_calls_per_day,2),
            third_party_average_length_of_calls: round(third_party_average_length_of_calls,0),
            third_party_hourly_wage_of_observer: round(third_party_hourly_wage_of_observer,0),
            third_party_hours_spent_before: round(third_party_hours_spent_before,2),
            third_party_cost_of_labor_before: round(third_party_cost_of_labor_before,0),
            third_party_improvement: round(third_party_improvement,2),
            third_party_hours_spent_after: round(third_party_hours_spent_after,2),
            third_party_cost_of_labor_after: round(third_party_cost_of_labor_after,0),
            third_party_cost_of_labor_improvement: round(third_party_average_length_of_calls,0),
            third_party_cost_of_labor_fte: third_party_cost_of_labor_fte.map(x => round(x,1)),
            third_party_annual_benefit: third_party_annual_benefit.map(x => round(x,0)),

            virtual_downtime_number_of_tickets_per_day: round(virtual_downtime_number_of_tickets_per_day,0),
            virtual_downtime_delay_likelihood: round(virtual_downtime_delay_likelihood,2),
            virtual_downtime_avg_downtime_cost: round(virtual_downtime_avg_downtime_cost,2),
            virtual_downtime_length_of_delay: round(virtual_downtime_length_of_delay,2),
            virtual_downtime_number_of_delayed_fixes_before: round(virtual_downtime_number_of_delayed_fixes_before,2),
            virtual_downtime_cost_before: round(virtual_downtime_cost_before,0),
            virtual_downtime_improvement: round(virtual_downtime_improvement,2),
            virtual_downtime_number_of_delayed_fixes_after: round(virtual_downtime_number_of_delayed_fixes_after,2),
            virtual_downtime_cost_after: round(virtual_downtime_cost_after,0),
            virtual_downtime_cost_improvement: round(virtual_downtime_cost_improvement,0),
            virtual_downtime_annual_benefit: virtual_downtime_annual_benefit.map(x => round(x,0)),

            inperson_downtime_number_of_tickets_per_day: round(inperson_downtime_number_of_tickets_per_day,0),
            inperson_downtime_likelihood: round(inperson_downtime_likelihood,2),
            inperson_avg_downtime_cost: round(inperson_avg_downtime_cost,2),
            inperson_downtime_length_of_delay: round(inperson_downtime_length_of_delay,2),
            inperson_downtime_travel_expenses: round(inperson_downtime_travel_expenses,0),
            inperson_downtime_number_of_delayed_fixes_before: round(inperson_downtime_number_of_delayed_fixes_before,2),
            inperson_downtime_cost_before: round(inperson_downtime_cost_before,0),
            inperson_downtime_improvement: round(inperson_downtime_improvement,2),
            inperson_downtime_number_of_delayed_fixes_after: round(inperson_downtime_number_of_delayed_fixes_after,2),
            inperson_downtime_cost_after: round(inperson_downtime_cost_after,0),
            inperson_downtime_cost_improvement: round(inperson_downtime_cost_improvement,0),
            inperson_downtime_annual_benefit: inperson_downtime_annual_benefit.map(x => round(x,0)),

            jumpbox_lease_number_of_jumpboxes: round(jumpbox_lease_number_of_jumpboxes,0),
            jumpbox_lease_cost_per_year: round(jumpbox_lease_cost_per_year,0),
            jumpbox_lease_installation_cost: round(jumpbox_lease_installation_cost,0),
            jumpbox_lease_hawdware_lease_cost: round(jumpbox_lease_hawdware_lease_cost,0),
            jumpbox_lease_hardware_installation_cost: round(jumpbox_lease_hardware_installation_cost,0),
            jumpbox_lease_annual_benefit: jumpbox_lease_annual_benefit.map(x => round(x,0)),

            jumpbox_mtce_number_of_jumpboxes: round(jumpbox_mtce_number_of_jumpboxes,0),
            jumpbox_mtce_likelihood: round(jumpbox_mtce_likelihood,0),
            jumpbox_mtce_cost: round(jumpbox_mtce_cost,0),
            jumpbox_mtce_annual_cost: round(jumpbox_mtce_annual_cost,0),
            jumpbox_mtce_annual_benefit: jumpbox_mtce_annual_benefit.map(x => round(x,0)),

            year1_reduction: [
                {record_id:'year1_1', id: 'Reduction in observing 3rd Party', value: third_party_annual_benefit[0]},
                {record_id:'year1_2',id: 'Reduction in downtime for virtual tickets', value: virtual_downtime_annual_benefit[0]},
                {record_id:'year1_3',id: 'Reduction in downtime for in person tikets', value: inperson_downtime_annual_benefit[0]},
                {record_id:'year1_4',id: 'Reduction in lease of jump boxes', value: jumpbox_lease_annual_benefit[0]},
                {record_id:'year1_5',id: 'Reduction in maintenance of Jump Boxes', value: jumpbox_mtce_annual_benefit[0]}
            ].map((x, i) => ({ ...x, color:"hsl(" + 360 * i / 12 + ", 80%, 50%)"})),
            year2_reduction: [
                {record_id:'year2_1',id: 'Reduction in observing 3rd Party', value: third_party_annual_benefit[1]},
                {record_id:'year2_2',id: 'Reduction in downtime for virtual tickets', value: virtual_downtime_annual_benefit[1]},
                {record_id:'year2_3',id: 'Reduction in downtime for in person tikets', value: inperson_downtime_annual_benefit[1]},
                {record_id:'year2_4',id: 'Reduction in lease of jump boxes', value: jumpbox_lease_annual_benefit[1]},
                {record_id:'year2_5',id: 'Reduction in maintenance of Jump Boxes', value: jumpbox_mtce_annual_benefit[1]}
            ].map((x, i) => ({ ...x, color:"hsl(" + 360 * i / 12 + ", 80%, 50%)"})),
            year3_reduction: [
                {record_id:'year3_1',id: 'Reduction in observing 3rd Party', value: third_party_annual_benefit[2]},
                {record_id:'year3_2',id: 'Reduction in downtime for virtual tickets', value: virtual_downtime_annual_benefit[2]},
                {record_id:'year3_3',id: 'Reduction in downtime for in person tikets', value: inperson_downtime_annual_benefit[2]},
                {record_id:'year3_4',id: 'Reduction in lease of jump boxes', value: jumpbox_lease_annual_benefit[2]},
                {record_id:'year3_5',id: 'Reduction in maintenance of Jump Boxes', value: jumpbox_mtce_annual_benefit[2]}
            ].map((x, i) => ({ ...x, color:"hsl(" + 360 * i / 12 + ", 80%, 50%)"})),
            year4_reduction: [
                {record_id:'year4_1',id: 'Reduction in observing 3rd Party', value: third_party_annual_benefit[3]},
                {record_id:'year4_2',id: 'Reduction in downtime for virtual tickets', value: virtual_downtime_annual_benefit[3]},
                {record_id:'year4_3',id: 'Reduction in downtime for in person tikets', value: inperson_downtime_annual_benefit[3]},
                {record_id:'year4_4',id: 'Reduction in lease of jump boxes', value: jumpbox_lease_annual_benefit[3]},
                {record_id:'year4_5',id: 'Reduction in maintenance of Jump Boxes', value: jumpbox_mtce_annual_benefit[3]}
            ].map((x, i) => ({ ...x, color:"hsl(" + 360 * i / 12 + ", 80%, 50%)"})),
            year5_reduction: [
                {record_id:'year5_1',id: 'Reduction in observing 3rd Party', value: third_party_annual_benefit[4]},
                {record_id:'year5_2',id: 'Reduction in downtime for virtual tickets', value: virtual_downtime_annual_benefit[4]},
                {record_id:'year5_3',id: 'Reduction in downtime for in person tikets', value: inperson_downtime_annual_benefit[4]},
                {record_id:'year5_4',id: 'Reduction in lease of jump boxes', value: jumpbox_lease_annual_benefit[4]},
                {record_id:'year5_5',id: 'Reduction in maintenance of Jump Boxes', value: jumpbox_mtce_annual_benefit[4]}
            ].map((x, i) => ({ ...x, color:"hsl(" + 360 * i / 12 + ", 80%, 50%)"})),
            total_reduction: [
                {record_id:'total_reduction_1',id: 'Reduction in observing 3rd Party', value: third_party_annual_benefit.reduce((a, b) => a + b, 0)},
                {record_id:'total_reduction_2',id: 'Reduction in downtime for virtual tickets', value: virtual_downtime_annual_benefit.reduce((a, b) => a + b, 0)},
                {record_id:'total_reduction_3',id: 'Reduction in downtime for in person tikets', value: inperson_downtime_annual_benefit.reduce((a, b) => a + b, 0)},
                {record_id:'total_reduction_4',id: 'Reduction in lease of jump boxes', value: jumpbox_lease_annual_benefit.reduce((a, b) => a + b, 0)},
                {record_id:'total_reduction_5',id: 'Reduction in maintenance of Jump Boxes', value: jumpbox_mtce_annual_benefit.reduce((a, b) => a + b, 0)}
            ].map((x, i) => ({ ...x, color:"hsl(" + 360 * i / 12 + ", 80%, 50%)"})),
            reduction_third_party: [{ label: "Reduction in observing 3rd Party", "Before Cyolo":third_party_cost_of_labor_before, "After Cyolo":third_party_cost_of_labor_after}],
            reduction_virtual: [{ label: "Reduction in downtime for virtual tickets", "Before Cyolo":virtual_downtime_cost_before, "After Cyolo":virtual_downtime_cost_after}],
            reduction_inperson: [{ label: "Reduction in downtime for in person tikets", "Before Cyolo":inperson_downtime_cost_before, "After Cyolo":inperson_downtime_cost_after}],

        };

}

