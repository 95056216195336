import React from 'react';

function Text(props) {
    const fieldClass = props.fieldColor + "-field";
    const fieldName = props.fieldName;
    const value = props.value;
    const label = props.label;
    const required = props.required;
    const handleChange = props.handleChange;
    const pattern = props.pattern;
    const error = props.error;
    const leftMarker = props.leftMarker;
    const rightMarker = props.rightMarker;
    const width = props.width || "100%";
    const nullable = props.nullable || false;

    const focusHandler = (e) => {
        if (e.target.value === "0" && pattern) {
            e.target.value = "";
        }
    }
    const blurHandler = (e) => {
        if (e.target.value === "" && pattern) {
            e.target.value = "0";
        }
    }
    return (
        <div className={fieldClass}>
            {label && <label htmlFor={fieldName} className={"form-label" + (required ? " requiredField" : "")}>{label}</label>}
            <div style={{ width: width }}>
                <div className="input-group" >
                    {leftMarker && <div className="input-group-text">{leftMarker}</div>}
                    <input type="text" className="form-control" id={fieldName} placeholder=""
                        value={value || ''}
                        onFocus={(e) => { focusHandler(e) }}
                        onBlur={(e) => { if (!nullable) { blurHandler(e) } }}

                        onChange={(e) => { handleChange(fieldName, (e.target.validity.valid) ? e.target.value : '') }}
                        pattern={pattern ? pattern : ".*"} />
                    {rightMarker && <div className="input-group-text">{rightMarker}</div>}
                </div>
            </div>
            {error && <div className="validation-error">{error}</div>}
        </div>
    )
}
export default Text;