import React, { useEffect, useRef, useState } from 'react';
import Text from './fields/Text';

const InputForm = (props) => {
    console.log(props);
    const input_data = props.inputData;
    const handleChange = props.handleChange;



    return <>
        {input_data && <div className="row row-gap-3">
            <div className='col-12'>
                <h4 style={{ fontSize: "1.2rem" }}>Key Assumptions</h4>
            </div>
            <div className='col-6'>
                <Text
                    fieldColor="blue"
                    fieldName="customer_name"
                    value={input_data.customer_name || ""}
                    label="Customer Name"
                    required={true}
                    handleChange={handleChange}
                // pattern="[0-9]*"
                // leftMarker="#"
                // error={errors["step1__meta_model_name"]}
                />
            </div>
            <div className='col-6'>
                <Text
                    fieldColor="blue"
                    fieldName="site_count"
                    value={input_data.site_count || ""}
                    label="Number of Sites"
                    required={true}
                    handleChange={handleChange}
                    pattern="[0-9]*"
                    leftMarker="#"
                // error={errors["step1__meta_model_name"]}
                />
            </div>
            <div className='col-6'>
                <Text
                    fieldColor="blue"
                    fieldName="chance_observerd_call"
                    value={input_data.chance_observerd_call || ""}
                    label="Chance of a site having an observed call (per day)"
                    required={true}
                    handleChange={handleChange}
                    pattern="[0-9]*"
                    rightMarker="%"
                // error={errors["step1__meta_model_name"]}
                />
            </div>
            <div className='col-6'>
                <Text
                    fieldColor="blue"
                    fieldName="chance_ticket"
                    value={input_data.chance_ticket || ""}
                    label="Chance of a site having a ticket each day"
                    required={true}
                    handleChange={handleChange}
                    pattern="[0-9]*"
                    rightMarker="%"
                // error={errors["step1__meta_model_name"]}
                />
            </div>
            <div className='col-12'>
                <h4 style={{ fontSize: "1.2rem", marginTop: "30px" }}>Common Assumptions</h4>
            </div>
            <div className='col-6'>
                <Text
                    fieldColor="sky"
                    fieldName="avg_call_length_minutes"
                    value={input_data.avg_call_length_minutes || ""}
                    label="Average length of call (minutes)"
                    required={true}
                    handleChange={handleChange}
                    pattern="[0-9]*"
                    rightMarker="minutes"
                // error={errors["step1__meta_model_name"]}
                />
            </div>
            <div className='col-6'>
                <Text
                    fieldColor="sky"
                    fieldName="houlry_wage_usd_observer"
                    value={input_data.houlry_wage_usd_observer || ""}
                    label="Hourly wage of observer"
                    required={true}
                    handleChange={handleChange}
                    pattern="[0-9]*"
                    leftMarker="$"
                // error={errors["step1__meta_model_name"]}
                />
            </div>
            <div className='col-6'>
                <Text
                    fieldColor="sky"
                    fieldName="delay_likelihood"
                    value={input_data.delay_likelihood || ""}
                    label="Likelihood of ticket being delayed"
                    required={true}
                    handleChange={handleChange}
                    pattern="[0-9]*"
                    rightMarker="%"
                // error={errors["step1__meta_model_name"]}
                />
            </div>
            <div className='col-6'>
                <Text
                    fieldColor="sky"
                    fieldName="avg_hourly_cost_usd_downtime"
                    value={input_data.avg_hourly_cost_usd_downtime || ""}
                    label="Average hourly cost of downtime"
                    required={true}
                    handleChange={handleChange}
                    pattern="[0-9]*"
                    leftMarker="$"
                // error={errors["step1__meta_model_name"]}
                />
            </div>
            <div className='col-6'>
                <Text
                    fieldColor="sky"
                    fieldName="person_likelihood"
                    value={input_data.person_likelihood || ""}
                    label="Likelihood of ticket needing in person solution"
                    required={true}
                    handleChange={handleChange}
                    pattern="[0-9]*"
                    rightMarker="%"
                // error={errors["step1__meta_model_name"]}
                />
            </div>
            <div className='col-6'>
                <Text
                    fieldColor="sky"
                    fieldName="delay_virtual_hours"
                    value={input_data.delay_virtual_hours || ""}
                    label="Length of virtual delay (hours)"
                    required={true}
                    handleChange={handleChange}
                    pattern="[0-9]*"
                    rightMarker="hours"
                // error={errors["step1__meta_model_name"]}
                />
            </div>
            <div className='col-6'>
                <Text
                    fieldColor="sky"
                    fieldName="delay_inperson_hours"
                    value={input_data.delay_inperson_hours || ""}
                    label="Length of in person delay (hours)"
                    required={true}
                    handleChange={handleChange}
                    pattern="[0-9]*"
                    rightMarker="hours"
                // error={errors["step1__meta_model_name"]}
                />
            </div>
            <div className='col-6'>
                <Text
                    fieldColor="sky"
                    fieldName="expense_usd_travel_labor"
                    value={input_data.expense_usd_travel_labor || ""}
                    label="Travel and labor expenses for each in person fix"
                    required={true}
                    handleChange={handleChange}
                    pattern="[0-9]*"
                    leftMarker="$"
                // error={errors["step1__meta_model_name"]}
                />
            </div>
            <div className='col-6'>
                <Text
                    fieldColor="sky"
                    fieldName="number_of_jump_boxes"
                    value={input_data.number_of_jump_boxes || ""}
                    label="Number of Jump boxes "
                    required={true}
                    handleChange={handleChange}
                    pattern="[0-9]*"
                    leftMarker="#"
                // error={errors["step1__meta_model_name"]}
                />
            </div>
            <div className='col-6'>
                <Text
                    fieldColor="sky"
                    fieldName="yearly_lease_cost_usd_jump_box"
                    value={input_data.yearly_lease_cost_usd_jump_box || ""}
                    label="Yearly lease cost per Jump box"
                    required={true}
                    handleChange={handleChange}
                    pattern="[0-9]*"
                    leftMarker="$"
                // error={errors["step1__meta_model_name"]}
                />
            </div>
            <div className='col-6'>
                <Text
                    fieldColor="sky"
                    fieldName="installation_cost_usd_jump_box"
                    value={input_data.installation_cost_usd_jump_box || ""}
                    label="Cost of installation per box"
                    required={true}
                    handleChange={handleChange}
                    pattern="[0-9]*"
                    leftMarker="$"
                // error={errors["step1__meta_model_name"]}
                />
            </div>
            <div className='col-6'>
                <Text
                    fieldColor="sky"
                    fieldName="yearly_maintenance_likelihood"
                    value={input_data.yearly_maintenance_likelihood || ""}
                    label="Likelihood of maintenance per year"
                    required={true}
                    handleChange={handleChange}
                    pattern="[0-9]*"
                    rightMarker="%"
                // error={errors["step1__meta_model_name"]}
                />
            </div>
            <div className='col-6'>
                <Text
                    fieldColor="sky"
                    fieldName="installation_cost_usd_jump_box"
                    value={input_data.installation_cost_usd_jump_box || ""}
                    label="Cost of maintenance   "
                    required={true}
                    handleChange={handleChange}
                    pattern="[0-9]*"
                    leftMarker="$"
                // error={errors["step1__meta_model_name"]}
                />
            </div>
            {/* <div className='col-6'>
                <Text
                    fieldColor="sky"
                    fieldName="yoy_increase"
                    value={input_data.yoy_increase || ""}
                    label="Annual staff cost increase"
                    required={true}
                    handleChange={handleChange}
                    pattern="[0-9]*"
                    rightMarker="%"
                // error={errors["step1__meta_model_name"]}
                /> 
            </div>*/}
            <div className='col-12'>
                <h4 style={{ fontSize: "1.2rem", marginTop: "30px" }}>Other Assumptions</h4>
            </div>
            <div className='col-6'>
                <Text
                    fieldColor="orange"
                    fieldName="third_party_improvement"
                    value={input_data.third_party_improvement || ""}
                    label="Anticiapted improvement in 3rd Party user costs"
                    required={true}
                    handleChange={handleChange}
                    pattern="[0-9]*"
                    rightMarker="%"
                // error={errors["step1__meta_model_name"]}
                />
            </div>
            <div className='col-6'>
                <Text
                    fieldColor="orange"
                    fieldName="virtual_downtime_improvement"
                    value={input_data.virtual_downtime_improvement || ""}
                    label="Anticiapted improvement in downtime for virtual tickets"
                    required={true}
                    handleChange={handleChange}
                    pattern="[0-9]*"
                    rightMarker="%"
                // error={errors["step1__meta_model_name"]}
                />
            </div>
            <div className='col-6'>
                <Text
                    fieldColor="orange"
                    fieldName="inperson_downtime_improvement"
                    value={input_data.inperson_downtime_improvement || ""}
                    label="Anticiapted improvement in downtime for in person tickets"
                    required={true}
                    handleChange={handleChange}
                    pattern="[0-9]*"
                    rightMarker="%"
                // error={errors["step1__meta_model_name"]}
                />
            </div>

        </div>}
    </>

}
export default InputForm;